<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
            <v-col cols="12">
                <Datatable
                        :v-show="true"
                        ref="printerDatatable"
                        :api-endpoint="ENDPOINTS.DATATABLES.PRINTSETTINGS.PRINTER"
                        :datatable-headers="datatableHeaders"
                        no-excel-export
                        :show-select-checkbox="false"
                        show-edit-buttons
                        show-delete-buttons
                        @editEntry="editPrinter"
                        @deleteEntry="deletePrinter"
                        :permissionDelete="this.$store.getters['permissions/checkPermission']('allowDeletePrinter')"
                        :permissionEdit="this.$store.getters['permissions/checkPermission']('allowEditPrinter')"
                />
            </v-col>
            <v-col cols="12">
                <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                    <vue-touch-keyboard
                            id="onScreenKeyboard"
                            class="internalWidthExpanded"
                            :options="touchKeyboard.options"
                            :defaultKeySet="touchKeyboard.keySet"
                            v-if="touchKeyboard.visible"
                            :layout="touchKeyboard.layout"
                            :cancel="hideTouchKeyboard"
                            :accept="hideTouchKeyboard"
                            :input="touchKeyboard.input"
                    />
                </div>
            </v-col>
        </v-row>

        <v-dialog :value="deleteWarningDialog" max-width="300">
            <v-card v-if="printerDelete !== null">
                <v-card-title>{{ printerDelete.name }}</v-card-title>
                <v-card-text>
                    {{$t('generic.lang_deletePrinterBody')}}

                    <v-alert :value="deleteErrorMessage.length > 0" text color="primary">{{ deleteErrorMessage }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" text @click="cancelDeletePrinter" :disabled="deleteLoading">{{$t('generic.lang_cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="confirmDeletePrinter" :disabled="deleteLoading" :loading="deleteLoading">{{$t('generic.lang_delete')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../../config";
    import mixin from "../../../../mixins/KeyboardMixIns";

    //components
    import Datatable from "../../../datatable/Datatable";

    export default {
        name: "Printers",

        components: {
            Datatable
        },

        mixins: [mixin],

        data: () => {
            return {
                ENDPOINTS,
                deleteWarningDialog: false,
                printerDelete: null,
                deleteLoading: false,
                deleteErrorMessage: ""
            };
        },

        computed: {
            datatableHeaders: function () {
                return [
                    {
                        text: "ID",
                        align: "left",
                        value: "id",
                        width: 80,
                        hide: true
                    },
                    {
                        text: this.$t('generic.lang_name'),
                        value: "name"
                    },
                    {
                        text: this.$t('generic.lang_type'),
                        value: "type"
                    },
                    {
                        text: this.$t('generic.lang_gateway'),
                        value: "gateway"
                    },
                    {
                        text: this.$t('generic.lang_printerAddress'),
                        value: "address"
                    },
                    {
                        text: this.$t('generic.lang_cashierID'),
                        value: "cashierID"
                    },
                ];
            }
        },

        methods: {
            editPrinter(printer) {
                this.$router.push({
                    name: "settings.printerSettings.printerManager.editPrinter",
                    params: {
                        id: parseInt(printer.id)
                    }
                });
            },
            deletePrinter(printer) {
                this.printerDelete = printer;

                this.deleteWarningDialog = true;
            },
            confirmDeletePrinter() {
                this.deleteLoading = true;
                this.deleteErrorMessage = "";

                this.axios.post(ENDPOINTS.PRINTSETTINGS.PRINTER.DELETE, {
                    printerID: this.printerDelete.id
                }).then((res) => {
                    if(res.data.success) {
                        this.$refs.printerDatatable.getDataFromApi();
                        this.cancelDeletePrinter();
                    }
                    else {
                        this.deleteErrorMessage = res.data.msg;
                    }

                    this.deleteLoading = false;
                }).catch(() => {
                    this.deleteErrorMessage = this.$t('generic.lang_errorOccurred');
                    this.deleteLoading = false;
                })
            },
            cancelDeletePrinter() {
                this.printerDelete = null;
                this.deleteWarningDialog = false;
            }
        }
    }
</script>