<template>
    <div>
        <PageTitle
                :heading="$t('settings.lang_nav_settings_printermanager')"
                :subheading="$t('settings.lang_printerSettings')"
                :icon=icon
                show-add-button
                url-add='/settings/printerSettings/printerManager/createPrinter'
                :permissionAdd="this.$store.getters['permissions/checkPermission']('allowCreatePrinter')"
        ></PageTitle>

        <Printers></Printers>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle";
    import Printers from "../../../components/settings/printerSettings/printManager/Printers";

    export default {
        components: {
            Printers,
            PageTitle
        },

        data() {
            return {
                icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
            }
        }
    }
</script>